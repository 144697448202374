import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './router';
// import {Provider} from 'react-redux';
// import store from './redux/store'
import {hot} from 'react-hot-loader/root';
import HttpUtils from './util/http-utils'
import './util/rem.js'
import axios from 'axios'
// import 'core-js/es'  
// import 'react-app-polyfill/ie9'  
// import 'react-app-polyfill/stable'
import { Provider } from "mobx-react"//����mobx
import test from './mobox/test2'//����mosbx
const baseUrl = 'http://cec.theiavis.com/';
(React.Component.prototype).$http = new HttpUtils(baseUrl)
React.Component.prototype.baseUrl = baseUrl
const HotRoutes = hot(Routes)
let  utl = "/font"
axios.post(baseUrl+'yxyadmin/common/oss'+utl,{
  "fileName":"SourceHanSansCN-Light.otf,SourceHanSansCN-Normal.otf,SourceHanSansCN-Regular.otf,SourceHanSansCN-ExtraLight.otf,SourceHanSansCN-Medium.otf,SourceHanSansCN-Bold.otf"})
.then(res => {
    if(res.data.code == 0) {
    var data=res.data.data;
    var Light=data[0];
    var Normal=data[1];
    var Regular=data[2];
    var ExtraLight=data[3];
    var Medium=data[4];
    var Bold=data[5];
    var newStyle = document.createElement('style');
    newStyle.appendChild(document.createTextNode(`
    @font-face {
    font-family: Light;
    src: url(${Light}) format("OpenType")
    }
    @font-face {
    font-family: Normal;
    src: url(${Normal}) format("OpenType")
    }
    @font-face {
    font-family: Bold;
    src: url(${Bold}) format("OpenType")
    }
    @font-face {
    font-family: Regular;
    src: url(${Regular}) format("OpenType")
    }
    @font-face {
    font-family: ExtraLight;
    src: url(${ExtraLight}) format("OpenType")
    }
    @font-face {
    font-family: Medium;
    src: url(${Medium}) format("OpenType")
    }`));
         document.head.appendChild(newStyle);
  //  document.getElementById("root").style.fontFamily='Light';
        
    }
}).catch(function (error) {
    // console.log(error);
 });
 //
 axios.post(baseUrl+'/yxyadmin/common/oss/img',{
   "fileName":"hand.png,mainBody.png,title.png,vis.png"})
 .then(res => {
     if(res.data.code == 0) {
        var data=res.data.data;
        localStorage.setItem('imgList', JSON.stringify(data))
     }
 }).catch(function (error) { });
const stores = {
    test//
}; 
ReactDOM.render(
    <Provider {...stores}>
        <HotRoutes/>
    </Provider>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
